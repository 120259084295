import React from "react";
import {
  StyleSheet,
  View,
  FlatList,
  Image,
  TouchableOpacity,
} from "react-native-web";
import { connect, useSelector } from "react-redux";
import CardInfoView from "../../CardList/components/CardInfoView";
import { Constants, Fonts, Values, Colors } from "../../../../config";
import { CImage } from "../../../atoms";
import ActiveButton from "../../CardList/components/ActiveButton";

const GridCardItem = (props) => {
  const { general, isMobileVersion } = props;
  const ITEM_WIDTH = isMobileVersion ? "90%" : (Values.deviceWidth - 6) / 3;
  const ITEM_HEIGHT = isMobileVersion
    ? (Values.deviceWidth - 6) / 3
    : (Values.deviceWidth - 6) / 3;
  const onItemAction = (data) => {
    if (props.onItemAction) {
      props.onItemAction(data);
    }
  };
  const { item, index, hasMask, hideAdd } = props;
  const isLastItem = isMobileVersion ? index % 3 == 2 : index % 6 == 2;
  const { isHeadliner } = item;
  // const isActive =
  //   item.isFollow || item.isGoing || item.isSaving || item.isActive
  const isActive =
    (props.category === Constants.categoryType.CATEGORY_FESTIVAL
      ? general?.festivalGoingList?.includes(props.item.profileId) ||
        general?.festivalSaveList?.includes(props.item.profileId)
      : false) ||
    (props.category === Constants.categoryType.CATEGORY_ARTIST
      ? general?.artistFollowList?.includes(props.item.profileId)
      : false) ||
    (props.category === Constants.categoryType.CATEGORY_OTHER ||
    props.category === Constants.categoryType.CATEGORY_USER
      ? general?.userFollowList?.includes(props.item.profileId || props.item.id)
      : false);
  const renderInfoView = ({ item, index }) => {
    if (props.renderInfoView) {
      return props.renderInfoView({ item, index });
    }
    return (
      <CardInfoView
        item={props.item}
        category={props.category}
        style={styles.info}
        isMobileVersion={isMobileVersion}
      />
    );
  };
  const renderBackground = () => {
    if (props.renderBackground) {
      return (
        <View style={styles.backgroundImage}>
          {props.renderBackground({ item })}
        </View>
      );
    }
    return <CImage imgUrl={item.avatar} style={styles.backgroundImage} />;
  };
  return (
    <View
      style={[
        styles.itemContainer,
        isMobileVersion
          ? {
              width: (Values.deviceWidth - 9) / 3 - 3,
              height: 180,
              marginRight: 3,
              marginBottom: 3,
            }
          : {
              width: 170,
              height: 250,
            },
        isLastItem && { marginRight: isMobileVersion ? 0 : 8 },
      ]}>
      {isHeadliner && (
        <div
          className="bg-gradient-to-l from-gradient3 via-gradient2 to-gradient1"
          style={{
            position: "absolute",
            left: 0,
            top: 0,
            right: 0,
            bottom: 0,
          }}
        />
      )}
      <TouchableOpacity
        style={[
          styles.itemContentContainer,
          isMobileVersion
            ? {
                width: ITEM_WIDTH,
                height: ITEM_HEIGHT,
              }
            : {
                width: ITEM_WIDTH - 4,
                height: ITEM_WIDTH * 1.52 - 4,
              },
          !isHeadliner && styles.noneHighlight,
        ]}
        onPress={() =>
          onItemAction({ type: Constants.actionType.ACTION_VIEW, item })
        }>
        {renderBackground()}
        {hasMask && (
          <div
            className="bg-gradient-to-t from-realblack to-transparent"
            style={styles.mask}
          />
        )}
        {renderInfoView({ item, index })}

        {/* {!hideAdd && (
          <ActiveButton
            style={[
              styles.plusButton,
              !isHeadliner && styles.noneHighlightPlus,
            ]}
            plusIcon={styles.plusIcon}
            isActive={isActive}
            onPress={() =>
              onItemAction({
                type: Constants.actionType.ACTION_PLUS,
                item: props.item,
              })
            }
          />
        )} */}
      </TouchableOpacity>
    </View>
  );
};

const styles = StyleSheet.create({
  itemContainer: {
    justifyContent: "center",
    alignItems: "center",
    overflow: "hidden",
    borderRadius: 8,
    marginRight: 8,
    marginBottom: 8,
  },
  itemContentContainer: {
    borderRadius: 8,
    flexDirection: "column",
    backgroundColor: Colors.black,
    justifyContent: "flex-end",
    overflow: "hidden",
  },
  noneHighlight: {
    width: "100%",
    height: "100%",
  },
  backgroundImage: {
    position: "absolute",
    width: "100%",
    height: "100%",
    left: 0,
    top: 0,
  },
  mask: {
    position: "absolute",
    width: "100%",
    height: "100%",
    left: 0,
    top: 0,
  },
  title: {
    fontSize: 16,
    lineHeight: 18,
    fontFamily: Fonts.primaryBold,
    color: Colors.white,
  },
  description: {
    fontSize: 16,
    lineHeight: 18.19,
    marginLeft: 2,
    fontFamily: Fonts.primaryRegular,
    color: Colors.white80,
  },
  rowContainer: {
    flexDirection: "row",
    alignItems: "center",
    marginTop: 4,
    marginBottom: 8,
  },
  infoContainer: {
    flexDirection: "column",
    justifyContent: "center",
    marginLeft: 8,
  },
  plusButton: {
    position: "absolute",
    right: 6,
    top: 6,
    width: 16,
    height: 16,
  },
  noneHighlightPlus: {
    right: 8,
    top: 8,
  },
  plusIcon: {
    width: 7.68,
    height: 7.68,
  },
  icon: {
    width: 10,
    height: 10,
  },
});

const mapStateToProps = (state) => ({
  general: state.general,
});

export default connect(mapStateToProps)(GridCardItem);
