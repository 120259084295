import React, { useEffect, useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import {
  StyleSheet,
  View,
  FlatList,
  Modal,
  Image,
  Text,
  TouchableOpacity,
} from "react-native-web";
import { Colors, Fonts, Images, Values } from "../../../config";
import { CImage } from "../../atoms";

const TagCategoryViewModal = (props) => {
  const { t } = useTranslation();
  const { isMobileVersion } = props;

  useEffect(() => {
    if (props.isVisible) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [props.isVisible]);

  if (!props.item) return null;
  const { icon, name, children } = props.item;
  const title = props.item.name || props.item.title;
  let contentText = "";

  if (children) {
    contentText = children
      .map((item) => {
        return item.name || item.title;
      })
      .join(", ");
  }
  const onClose = () => {
    document.body.style.overflow = "auto";
    if (props.onClose) {
      props.onClose();
    }
  };

  return (
    <View style={[props.style]}>
      <Modal transparent visible={props.isVisible}>
        <TouchableOpacity
          style={{
            backgroundColor: "rgba(89, 89, 89, 0.45)",
            width: "100%",
            height: "100%",
            position: "absolute",
            left: 0,
            top: 0,
          }}
          onPress={onClose}
        />
        <View style={styles.dim}>
          <TouchableOpacity
            activeOpacity={1}
            onPress={onClose}
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
            }}
          />

          <View
            style={[
              styles.modalContainer,
              isMobileVersion ? { width: 300, height: 82 } : {},
            ]}>
            <View
              style={[
                styles.bodyContainer,
                isMobileVersion
                  ? {
                      padding: 0,
                      justifyContent: "center",
                      alignItems: "center",
                    }
                  : {},
              ]}>
              <TouchableOpacity
                style={[
                  styles.closeIconContainer,
                  isMobileVersion ? { width: 20, height: 20, top: 16 } : {},
                ]}
                onPress={onClose}>
                <CImage
                  width={isMobileVersion ? 16 : 24}
                  height={isMobileVersion ? 16 : 24}
                  layout="fixed"
                  source={Images.iconClose}
                  style={styles.closeIcon}
                  resizeMode="contain"
                />
              </TouchableOpacity>
              <View style={styles.titleContainer}>
                <View style={{ marginRight: isMobileVersion ? 8 : 15 }}>
                  <CImage
                    imgUrl={icon}
                    objectFit="contain"
                    width={isMobileVersion ? 16 : 24}
                    height={isMobileVersion ? 16 : 24}
                    layout="fixed"
                    style={styles.titleIcon}
                    alt={title?.toLowerCase()}
                  />
                </View>
                <Text
                  style={[
                    styles.title,
                    isMobileVersion ? { fontSize: 16 } : {},
                  ]}>
                  {title}
                </Text>
              </View>
              <Text
                style={[
                  styles.contentText,
                  isMobileVersion ? { fontSize: 12, marginBottom: 5 } : {},
                ]}>
                {contentText}
              </Text>
            </View>
          </View>
        </View>
      </Modal>
    </View>
  );
};

const styles = StyleSheet.create({
  dim: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    alignSelf: "center",
    maxWidth: 480,
    width: "100%",
    height: "auto",
  },
  modalContainer: {
    margin: 32,
    position: "absolute",
    flex: 1,
    backgroundColor: Colors.black,
    borderWidth: 0,
    justifyContent: "center",
    borderRadius: 25,
    maxWidth: 480,
    width: "100%",
    zIndex: 500,
  },
  title: {
    fontFamily: Fonts.primaryBold,
    fontSize: 24,
    lineHeight: 20,
    color: Colors.white,
    textAlign: "center",
  },
  titleContainer: {
    flexDirection: "row",
    alignItems: "center",
  },
  titleIcon: {
    width: 16,
    height: 16,
    marginRight: 8,
  },
  bodyContainer: {
    flex: 1,
    alignItems: "center",
    paddingHorizontal: 16,
    paddingBottom: 20,
    paddingTop: 30,
  },
  icon: {
    height: 16,
    width: 16,
    resizeMode: "contain",
    marginRight: 6,
  },
  contentText: {
    fontSize: 16,
    fontFamily: Fonts.primaryRegular,
    color: Colors.white,
    textAlign: "center",
    marginTop: 12,
    marginBottom: 16,
  },
  closeIconContainer: {
    position: "absolute",
    top: 20,
    right: 16,
    width: 32,
    height: 32,
  },
  closeIcon: {
    width: 16,
    height: 16,
  },
});

export default TagCategoryViewModal;
