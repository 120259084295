import React from "react";
import { StyleSheet, Text, TouchableOpacity, View } from "react-native-web";
import { connect, useSelector } from "react-redux";
import ActiveButton from "./ActiveButton";
import CardInfoView from "./CardInfoView";
import { Colors, Constants, Fonts } from "../../../../config";
import { CImage } from "../../../atoms";
import { formatDate } from "../../../../helpers";
import moment from "moment";
const PortraitCardItem = (props) => {
  const { general, isChartsMode, chartIndex } = props;

  const onItemAction = (data) => {
    if (props.onItemAction) {
      props.onItemAction(data);
    }
  };

  const festivalStartDate = formatDate(
    props.item.startDate,
    Constants.dateFormat.DATE_FORMAT_SIMPLE
  );

  return (
    <div
      className={
        props.mapFocused === props.item?.id
          ? "bg-gradient-to-bl from-gradient3 via-gradient2 to-gradient1 p-1px"
          : ""
      }
      id={`items-${props.item?.id}`}
      style={
        props.category === Constants.categoryType.CATEGORY_FESTIVAL ||
        props.category === Constants.categoryType.CATEGORY_VENUE
          ? {
              width: props.fullWidth ? (props.mapView ? 347 : "97%") : 350,
              height: 250,
              borderRadius: 16,
              marginBottom: 20,
              marginRight: 10,
            }
          : {
              width: props.fullWidth ? (props.mapView ? 347 : "97%") : 230,
              height: 250,
              borderRadius: 16,
              marginBottom: 20,
              marginRight: 10,
            }
      }
      onMouseOver={() =>
        props.setMapFocused ? props.setMapFocused(props.item?.id) : null
      }>
      <View
        // activeOpacity={1}
        style={[
          styles.container,
          {
            width: "100%",
            height: props.mapFocused === props.item?.id ? 248 : 250,
          },
          props.itemContainerStyle,
        ]}
        // onPress={() => {
        //   onItemAction({
        //     type: Constants.actionType.ACTION_VIEW,
        //     item: props.item,
        //   });
        // }}
      >
        {props.category === Constants.categoryType.CATEGORY_OTHER ||
        props.category === Constants.categoryType.CATEGORY_ARTIST ? (
          <View style={styles.backgroundImageContainer}>
            <CImage
              imgUrl={
                props.item.avatar ||
                (props.category === Constants.categoryType.CATEGORY_OTHER
                  ? "Local:defaultOther"
                  : "Local:defaultArtist")
              }
              style={styles.backgroundImage}
              resizeMode="cover"
              alt={`Avatar ${props?.item?.displayName}`}
            />
          </View>
        ) : (
          <View style={styles.backgroundImageContainer}>
            <CImage
              imgUrl={
                props.item.avatar
                  ? props.item.avatar
                  : props.category === Constants.categoryType.CATEGORY_ARTIST
                  ? "Local:defaultArtist"
                  : props.category === Constants.categoryType.CATEGORY_FESTIVAL
                  ? "Local:defaultFestival"
                  : props.category === Constants.categoryType.CATEGORY_VENUE
                  ? "Local:defaultVenue"
                  : "Local:defaultOther"
              }
              style={styles.backgroundImage}
              resizeMode="cover"
              alt={
                props.category === Constants.categoryType.CATEGORY_VENUE
                  ? `${props?.item?.displayName} Logo`
                  : `${props?.item?.title} ${moment(
                      props?.item?.startDate
                    ).format("YYYY")} Logo`
              }
            />
          </View>
        )}
        {props.category === Constants.categoryType.CATEGORY_FESTIVAL && (
          <div className="bg-black9 absolute top-0 left-0 m-4 rounded-xl w-12 h-14 flex flex-col justify-center items-center">
            <div className="font-regular uppercase text-xs text-white40">
              {festivalStartDate?.substring(0, 3)}
            </div>
            <div className="font-bold uppercase text-xs text-white">
              {festivalStartDate?.substring(festivalStartDate?.length - 3, 3)}
            </div>
            <div className="font-regular uppercase text-xs text-white40">
              {festivalStartDate?.substring(
                festivalStartDate?.length - 3,
                festivalStartDate?.length
              )}
            </div>
          </div>
        )}
        <div
          className="bg-gradient-to-t from-realblack to-transparent"
          style={styles.mask}
        />
        <CardInfoView
          item={props.item}
          category={props.category}
          isHome={props.isHome}
          style={styles.info}
        />
        {isChartsMode && (
          <View style={styles.infoBox}>
            <div
              className="bg-gradient-to-bl from-gradient3 via-gradient2 to-gradient1"
              style={{
                position: "absolute",
                left: 0,
                top: 0,
                right: 0,
                bottom: 0,
                zIndex: 0,
              }}
            />
            <Text style={[styles.infoBoxText, { marginLeft: 0 }]}>
              {chartIndex + 1}
            </Text>
          </View>
        )}
        {/* <ActiveButton
        style={styles.plusButton}
        isActive={
          (props.category === Constants.categoryType.CATEGORY_FESTIVAL
            ? general?.festivalGoingList?.includes(props.item.profileId) ||
              general?.festivalSaveList?.includes(props.item.profileId)
            : false) ||
          (props.category === Constants.categoryType.CATEGORY_ARTIST
            ? general?.artistFollowList?.includes(props.item.profileId)
            : false) ||
          (props.category === Constants.categoryType.CATEGORY_OTHER ||
          props.category === Constants.categoryType.CATEGORY_USER
            ? general?.userFollowList?.includes(
                props.item.profileId || props.item.id
              )
            : false)
          // props.item.isActive ||
          // props.item.isGoing ||
          // props.item.isSaving ||
          // props.item.isConnect ||
          // props.item.isFollow
        }
        onPress={() =>
          onItemAction({
            type: Constants.actionType.ACTION_PLUS,
            item: props.item,
          })
        }
      /> */}
      </View>
    </div>
  );
};

const styles = StyleSheet.create({
  plusButton: {
    position: "absolute",
    right: 10,
    top: 10,
    width: 30,
    height: 30,
  },
  backgroundImageContainer: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    width: "100%",
    height: "100%",
    borderRadius: 16,
    overflow: "hidden",
  },
  backgroundImage: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    width: "100%",
    height: "100%",
  },
  mask: {
    position: "absolute",
    bottom: 0,
    height: 175,
    width: "100%",
    borderRadius: 15,
  },
  container: {
    width: 180,
    height: 230,
    flexDirection: "column",
    marginRight: 10,
    marginBottom: 20,
    justifyContent: "flex-end",
    // overflow: "hidden",
    position: "relative",
  },
  info: {
    paddingHorizontal: 16,
    paddingBottom: 16,
    alignSelf: "stretch",
  },
  infoBox: {
    position: "absolute",
    right: 15,
    top: 23,
    width: 32,
    height: 32,
    borderRadius: 60,
    overflow: "hidden",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "row",
  },
  infoBoxText: {
    fontSize: 12,
    lineHeight: 16,
    fontFamily: Fonts.primaryBold,
    color: Colors.white,
    position: "relative",
    zIndex: 10,
    marginLeft: 8,
    zIndex: 0,
  },
});

const mapStateToProps = (state) => ({
  general: state.general,
});

export default connect(mapStateToProps)(PortraitCardItem);
