import React, { useState, useEffect } from "react";
import { View, StyleSheet, Text, Image } from "react-native-web";
import { Colors, Constants, Fonts, Images, Values } from "../../../../config";
import { formatNumber } from "../../../../helpers";
import { getSpecialist } from "../../../../helpers/formatHelpers";
import { CImage, DotSplitedText } from "../../../atoms";
import DotSplitedViews from "../../../atoms/DotSplitedViews";

const UserInfoView = (props) => {
  const { categoryType, item, isMobileVersion, firstIndex, lastIndex } = props;
  const {
    displayName,
    country,
    location,
    totalEvent,
    totalConnection,
    totalFollower,
    togetherFollowing,
    isOfficial,
    isPro,
  } = item;
  const [showPopupOfficial, setShowPopupOfficial] = useState(false);
  let specialist = getSpecialist(item.specialist?.split(","));
  if (!item.specialist) {
    specialist = getSpecialist(item.genres);
  }
  const totalFollowerString = totalFollower || "-";
  const totalEventString = Number(totalEvent) || "-";
  let totalShareString = formatNumber(totalConnection) || "-";
  const genreString = specialist || "-";
  const locationString = country || location || "-";
  const getLocation = (location) => {
    const locationArray = location.split("");
    if (locationArray[0] === ",") {
      if (locationArray.length === 2) {
        return "-";
      } else {
        locationArray.shift();
      }
    }
    return locationArray;
  };
  const renderViews = () => {
    return isMobileVersion
      ? [
          <View key={1} style={styles.rowContainer}>
            {categoryType !== Constants.categoryType.CATEGORY_VENUE && (
              <CImage
                key={1}
                source={Images.iconTicket}
                width={isMobileVersion ? 12 : 16}
                height={isMobileVersion ? 12 : 16}
                layout="fixed"
                alt="ticket entry"
              />
            )}
            {categoryType !== Constants.categoryType.CATEGORY_VENUE && (
              <Text
                key={2}
                style={[
                  styles.infoText,
                  { marginLeft: 4, marginRight: 10 },
                  isMobileVersion
                    ? {
                        fontSize: 10,
                        lineHeight: 18,
                      }
                    : {},
                ]}>
                {totalEventString}
              </Text>
            )}
            {/* <CImage
              key={3}
              source={Images.iconSearchOther}
              width={isMobileVersion ? 18 : 20}
              height={isMobileVersion ? 18 : 20}
              layout="fixed"
              alt="followers"
            />
            <Text
              key={4}
              style={[
                styles.infoText,
                { marginLeft: 4 },
                isMobileVersion
                  ? {
                      fontSize: 10,
                      lineHeight: 18,
                    }
                  : {},
              ]}>
              {totalFollowerString}
            </Text> */}
          </View>,
        ]
      : [
          <View key={1} style={styles.rowContainer}>
            {categoryType !== Constants.categoryType.CATEGORY_VENUE && (
              <CImage
                key={1}
                source={Images.iconTicket}
                width={isMobileVersion ? 12 : 16}
                height={isMobileVersion ? 12 : 16}
                layout="fixed"
                alt="ticket entry"
              />
            )}
            {categoryType !== Constants.categoryType.CATEGORY_VENUE && (
              <Text
                key={2}
                style={[styles.infoText, { marginLeft: 4, marginRight: 10 }]}>
                {totalEventString}
              </Text>
            )}
            {/* <CImage
              key={3}
              source={Images.iconSearchOther}
              width={isMobileVersion ? 18 : 20}
              height={isMobileVersion ? 18 : 20}
              layout="fixed"
              alt="followers"
            />
            <Text key={4} style={[styles.infoText, { marginLeft: 4 }]}>
              {totalFollowerString}
            </Text> */}
          </View>,
        ];
  };

  return (
    <View style={[styles.infoContainer, { marginLeft: 20 }]}>
      {categoryType === Constants.categoryType.CATEGORY_VENUE ? (
        <View
          style={[
            styles.rowContainer,
            {
              marginBottom: 11,
              marginRight: 24,
            },
          ]}
          onMouseOver={() => setShowPopupOfficial(true)}
          onMouseLeave={() => setShowPopupOfficial(false)}>
          <Text
            ellipsizeMode={"tail"}
            numberOfLines={1}
            style={[
              styles.title,
              isMobileVersion
                ? {
                    fontSize: 12,
                    lineHeight: 18,
                  }
                : {},
              { marginBottom: 0, marginRight: 0 },
            ]}>
            {displayName}
          </Text>
          {(isOfficial === 1 || isPro === 1) && (
            <View
              style={{
                marginLeft: 6,
                marginTop: 0,
                position: "relative",
              }}>
              <CImage
                source={Images.iconVerifiedBadge}
                alt="badge official"
                width={14}
                height={14}
                layout="fixed"
              />

              {showPopupOfficial &&
                (isOfficial === 1 || isPro === 1) &&
                !isMobileVersion && (
                  <View
                    style={{
                      position: "absolute",
                      width: isMobileVersion ? 260 : 336,
                      height: isMobileVersion ? 140 : 182,
                      zIndex: 999,
                      left: 0,
                      top: lastIndex ? -110 : firstIndex ? -20 : -91,
                      paddingLeft: 24,
                    }}>
                    <View
                      style={{
                        backgroundColor: Colors.grayBackground,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        paddingHorizontal: 22,
                        borderRadius: 18,
                        width: "100%",
                        height: "100%",
                        boxShadow: "2px 2px 10px rgba(0,0,0,0.5)",
                      }}>
                      <CImage
                        source={Images.iconVerifiedBadgeMedium}
                        alt="verified account"
                        width={isMobileVersion ? 24 : 35}
                        height={isMobileVersion ? 24 : 35}
                        layout="fixed"
                      />
                      <Text
                        style={{
                          fontFamily: Fonts.primaryBold,
                          fontSize: isMobileVersion ? 16 : 20,
                          lineHeight: isMobileVersion ? 20 : 24,
                          color: Colors.white,
                          marginVertical: isMobileVersion ? 9 : 14,
                          textAlign: "center",
                        }}>
                        Official Account
                      </Text>
                      <Text
                        style={{
                          fontFamily: Fonts.primaryRegular,
                          fontSize: isMobileVersion ? 12 : 16,
                          lineHeight: isMobileVersion ? 16 : 18,
                          color: Colors.white80,
                          textAlign: "center",
                        }}>
                        This is an official account verified by Soundclub
                      </Text>
                    </View>
                  </View>
                )}
            </View>
          )}

          {showPopupOfficial &&
            (isOfficial === 1 || isPro === 1) &&
            isMobileVersion && (
              <View
                style={
                  lastIndex
                    ? {
                        position: "absolute",
                        width: isMobileVersion ? 260 : 336,
                        height: isMobileVersion ? 140 + 24 : 182 + 24,
                        zIndex: 999,
                        left: -100,
                        bottom: 0,
                        paddingBottom: 24,
                      }
                    : {
                        position: "absolute",
                        width: isMobileVersion ? 260 : 336,
                        height: isMobileVersion ? 140 + 24 : 182 + 24,
                        zIndex: 999,
                        left: -100,
                        top: 0,
                        paddingTop: 24,
                      }
                }>
                <View
                  style={{
                    backgroundColor: Colors.grayBackground,
                    width: isMobileVersion ? 260 : 336,
                    height: isMobileVersion ? 140 : 182,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    paddingHorizontal: 22,
                    borderRadius: 18,
                    width: "100%",
                    height: "100%",
                    boxShadow: "2px 2px 10px rgba(0,0,0,0.5)",
                  }}>
                  <CImage
                    source={Images.iconVerifiedBadgeMedium}
                    alt="verified account"
                    width={isMobileVersion ? 24 : 35}
                    height={isMobileVersion ? 24 : 35}
                    layout="fixed"
                  />
                  <Text
                    style={{
                      fontFamily: Fonts.primaryBold,
                      fontSize: isMobileVersion ? 16 : 20,
                      lineHeight: isMobileVersion ? 20 : 24,
                      color: Colors.white,
                      marginVertical: isMobileVersion ? 9 : 14,
                      textAlign: "center",
                    }}>
                    Official Account
                  </Text>
                  <Text
                    style={{
                      fontFamily: Fonts.primaryRegular,
                      fontSize: isMobileVersion ? 12 : 16,
                      lineHeight: isMobileVersion ? 16 : 18,
                      color: Colors.white80,
                      textAlign: "center",
                    }}>
                    This is an official account verified by Soundclub
                  </Text>
                </View>
              </View>
            )}
        </View>
      ) : (
        <Text
          ellipsizeMode={"tail"}
          numberOfLines={1}
          style={[
            styles.title,
            isMobileVersion
              ? {
                  fontSize: 12,
                  lineHeight: 18,
                }
              : {},
          ]}>
          {displayName}
        </Text>
      )}
      <View style={isMobileVersion ? {} : styles.rowContainer}>
        {categoryType === Constants.categoryType.CATEGORY_VENUE ? (
          <DotSplitedText
            texts={[props.item.tag_venue_1 || "-", props.item.city || "-"]}
            style={{ marginBottom: isMobileVersion ? 10 : 0 }}
            textStyle={{
              color: Colors.darkGray,
              fontSize: isMobileVersion ? 10 : 14,
            }}
          />
        ) : (
          <DotSplitedText
            texts={[genreString, getLocation(locationString)]}
            style={{ marginBottom: isMobileVersion ? 10 : 0 }}
            textStyle={{
              color: Colors.darkGray,
              fontSize: isMobileVersion ? 10 : 14,
            }}
          />
        )}

        <View
          style={[
            styles.rowContainer,
            { marginLeft: isMobileVersion ? 0 : 10 },
          ]}>
          <DotSplitedViews views={renderViews()} />
          {/* <View style={[styles.rowContainer, { marginLeft: 10 }]}>
            <CImage
              height={isMobileVersion ? 12 : 14}
              width={isMobileVersion ? 12 : 14}
              layout="fixed"
              source={Images.iconShareColored}
              alt="shared followers"
            />
            <Text
              style={[
                styles.infoText,
                { marginLeft: 4 },
                isMobileVersion
                  ? {
                      fontSize: 10,
                      lineHeight: 18,
                    }
                  : {},
              ]}>
              {"-"}
            </Text>
          </View> */}
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    alignSelf: "stretch",
  },
  icon: {
    width: 12,
    height: 12,
  },
  infoText: {
    fontSize: 14,
    lineHeight: 14,
    fontFamily: Fonts.primaryRegular,
    color: Colors.darkGray,
  },
  infoContainer: {
    flexDirection: "column",
    justifyContent: "center",
    flex: 1,
    paddingHorizontal: 10,
    // overflow: "hidden",
  },
  rowContainer: {
    flexDirection: "row",
    alignItems: "center",
  },
  title: {
    fontFamily: Fonts.primaryBold,
    fontSize: 16,
    lineHeight: 18,
    marginBottom: 11,
    alignSelf: "stretch",
    color: Colors.white,
    marginRight: 24,
  },
});

export default UserInfoView;
