import React from "react";
import { StyleSheet, View, Text, Image } from "react-native-web";
import { connect, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { IconizedButton } from "../../atoms";
import { Colors, Constants, Fonts, Images } from "../../../config";

const FollowContainer = (props) => {
  const { t } = useTranslation();
  const { general } = props;
  return (
    <View style={[styles.container, props.style]}>
      {(props.category === Constants.categoryType.CATEGORY_EVENT ||
        props.category === Constants.categoryType.CATEGORY_FESTIVAL) && (
        <IconizedButton
          icon={
            general?.festivalGoingList?.includes(props.item.profileId)
              ? Images.iconPlus
              : Images.iconPlusGray
          }
          iconStyle={styles.plusIcon}
          buttonTitle={t("key_going")}
          style={[styles.buttonStyle, { marginBottom: 16 }]}
          bordered={!general?.festivalGoingList?.includes(props.item.profileId)}
          onPress={() =>
            props.onPressItem(
              !general?.festivalGoingList?.includes(props.item.profileId),
              general?.festivalSaveList?.includes(props.item.profileId),
              "going"
            )
          }
          colored={general?.festivalGoingList?.includes(props.item.profileId)}
        />
      )}
      {(props.category === Constants.categoryType.CATEGORY_VENUE ||
        props.category === Constants.categoryType.CATEGORY_EVENT ||
        props.category === Constants.categoryType.CATEGORY_FESTIVAL) && (
        <IconizedButton
          icon={
            general?.festivalSaveList?.includes(props.item.profileId)
              ? Images.iconPlus
              : Images.iconPlusGray
          }
          iconStyle={styles.plusIcon}
          buttonTitle={t("key_btn_save")}
          style={[styles.buttonStyle, { marginBottom: 16 }]}
          bordered={!general?.festivalSaveList?.includes(props.item.profileId)}
          onPress={() =>
            props.onPressItem(
              general?.festivalGoingList?.includes(props.item.profileId),
              !general?.festivalSaveList?.includes(props.item.profileId),
              "save"
            )
          }
          colored={general?.festivalSaveList?.includes(props.item.profileId)}
        />
      )}
      {/* {props.category === Constants.categoryType.CATEGORY_VENUE && (
        <IconizedButton
          icon={props.item.isSaving ? Images.iconPlus : Images.iconPlusGray}
          iconStyle={styles.plusIcon}
          buttonTitle={t('key_btn_follow')}
          style={[styles.buttonStyle, { marginBottom: 16 }]}
          bordered={!props.item.isSaving}
          onPress={() =>
            props.onPressItem(props.item.isGoing, !props.item.isSaving, 'save')
          }
          colored={props.item.isSaving}
        />
      )} */}
      <IconizedButton
        buttonTitle={t("key_cancel")}
        style={[styles.buttonStyle, { marginBottom: 16 }]}
        filled
        onPress={props.onPressCancel}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: "column",
    alignSelf: "stretch",
    paddingTop: 24,
  },
  plusIcon: {
    width: 14,
    height: 14,
    resizeMode: "contain",
    marginRight: 4,
  },
});

const mapStateToProps = (state) => ({
  general: state.general,
});

export default connect(mapStateToProps)(FollowContainer);
