import React, { useState } from "react";
import {
  StyleSheet,
  View,
  FlatList,
  Image,
  TouchableOpacity,
  Text,
} from "react-native-web";
import { CImage, DotSplitedText } from "../../../atoms";
import { Fonts, Colors, Constants, Images } from "../../../../config";
import { formatDate } from "../../../../helpers";
import {
  diffFormat,
  formatCurrency,
  formatNumber,
  getSpecialist,
} from "../../../../helpers/formatHelpers";
import DotSplitedViews from "../../../atoms/DotSplitedViews";

const EventCardInfoView = (props) => {
  const { isMobileVersion } = props;
  const { startDate, streetLocation, location, city } = props.item;
  const eventStartDate = formatDate(
    startDate,
    Constants.dateFormat.DATE_FORMAT_SIMPLE
  );
  const eventStartTime = formatDate(
    startDate,
    Constants.dateFormat.TIME_FORMAT_SIMPLE
  );
  const dataLocation = streetLocation || location || city;

  return (
    <View style={[styles.container, props.style]}>
      <Text
        style={[
          styles.title,
          isMobileVersion
            ? {
                fontSize: 12,
                lineHeight: 18,
              }
            : {},
        ]}
        numberOfLines={1}>
        {props.item.title}
      </Text>
      <View style={styles.rowContainer}>
        <Text
          style={[
            styles.description,
            isMobileVersion
              ? {
                  fontSize: 10,
                  lineHeight: 18,
                }
              : {},
          ]}>
          {eventStartDate}
        </Text>
        <View style={styles.dot} />
        <Text
          style={[
            styles.description,
            isMobileVersion
              ? {
                  fontSize: 10,
                  lineHeight: 18,
                }
              : {},
          ]}>
          {eventStartTime}
        </Text>
      </View>
      <Text style={styles.location}>{dataLocation}</Text>
    </View>
  );
};

const FestivalCardInfoView = (props) => {
  const { isMobileVersion, isHome } = props;
  const {
    startDate,
    endDate,
    streetLocation,
    location,
    price,
    city,
    totalConnection,
    totalRelatedConnection,
    totalFollower,
    totalGoing,
    totalSaved,
    totalLineup,
    isOfficial,
    isPro,
  } = props.item;
  const [showPopupOfficial, setShowPopupOfficial] = useState(false);
  const festivalStartDate = formatDate(
    startDate,
    Constants.dateFormat.DATE_FORMAT_SIMPLE
  );
  const festivalStartTime = diffFormat(startDate, endDate, "days");
  const dataLocation = streetLocation || city || location;
  const formatedPrice = formatCurrency(price, Constants.currencyType.USD);
  const totalShare = formatNumber(totalConnection || totalRelatedConnection);
  const followerCount =
    formatNumber(totalFollower) === "-"
      ? formatNumber(Number(totalGoing) + Number(totalSaved))
      : formatNumber(totalFollower);

  return (
    <View style={[styles.container, props.style]}>
      <View
        style={[styles.rowContainer]}
        onMouseOver={() => setShowPopupOfficial(true)}
        onMouseLeave={() => setShowPopupOfficial(false)}>
        <Text
          style={[
            styles.title,
            isMobileVersion
              ? {
                  fontSize: 12,
                  lineHeight: 18,
                }
              : {},
          ]}
          numberOfLines={1}>
          {props.item.title}
        </Text>
        {(isOfficial === 1 || isPro === 1) && (
          <View
            style={{
              marginLeft: 6,
              marginTop: 0,
              position: "relative",
            }}>
            <CImage
              source={Images.iconVerifiedBadge}
              alt="badge official"
              width={14}
              height={14}
              layout="fixed"
            />
          </View>
        )}

        {showPopupOfficial && (isOfficial === 1 || isPro === 1) && !isHome && (
          <View
            style={{
              position: "absolute",
              width: isMobileVersion ? 260 : 336,
              height: isMobileVersion ? 140 + 24 : 182 + 24,
              zIndex: 10,
              left: -10,
              top: 0,
              paddingTop: 24,
            }}>
            <View
              style={{
                backgroundColor: Colors.grayBackground,
                width: isMobileVersion ? 260 : 336,
                height: isMobileVersion ? 140 : 182,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                paddingHorizontal: 22,
                borderRadius: 18,
                width: "100%",
                height: "100%",
                boxShadow: "2px 2px 10px rgba(0,0,0,0.5)",
              }}>
              <CImage
                source={Images.iconVerifiedBadgeMedium}
                alt="verified account"
                width={isMobileVersion ? 24 : 35}
                height={isMobileVersion ? 24 : 35}
                layout="fixed"
              />
              <Text
                style={{
                  fontFamily: Fonts.primaryBold,
                  fontSize: isMobileVersion ? 16 : 20,
                  lineHeight: isMobileVersion ? 20 : 24,
                  color: Colors.white,
                  marginVertical: isMobileVersion ? 9 : 14,
                  textAlign: "center",
                }}>
                Official Account
              </Text>
              <Text
                style={{
                  fontFamily: Fonts.primaryRegular,
                  fontSize: isMobileVersion ? 12 : 16,
                  lineHeight: isMobileVersion ? 16 : 18,
                  color: Colors.white80,
                  textAlign: "center",
                }}>
                This is an official account verified by Soundclub
              </Text>
            </View>
          </View>
        )}
      </View>
      <View style={[styles.rowContainer, { marginTop: 10 }]}>
        <Text
          style={[
            styles.description,
            isMobileVersion
              ? {
                  fontSize: 10,
                  lineHeight: 18,
                }
              : {},
          ]}>
          {festivalStartTime}
        </Text>
        <View style={styles.dot} />
        <Text
          style={[
            styles.description,
            { flex: 1 },
            isMobileVersion
              ? {
                  fontSize: 10,
                  lineHeight: 18,
                }
              : {},
          ]}
          numberOfLines={1}>
          {dataLocation}
        </Text>
      </View>
      <View style={[styles.rowContainer, { marginTop: 10 }]}>
        <View style={[styles.rowContainer]}>
          <CImage
            key={1}
            source={Images.iconSearchArtist}
            width={18}
            height={18}
            layout="fixed"
            alt="lineup artists"
          />
          <Text style={[styles.infoText, { marginLeft: 4 }]}>
            {formatNumber(totalLineup)}
          </Text>
        </View>
        {/* <View style={[styles.rowContainer, { marginLeft: 10 }]}>
          <CImage
            source={Images.iconSearchOther}
            width={20}
            height={20}
            layout="fixed"
            alt="followers"
          />
          <Text style={[styles.infoText, { marginLeft: 4 }]}>
            {followerCount}
          </Text>
        </View> */}
        {/* <View style={[styles.rowContainer, { marginLeft: 10 }]}>
          <CImage
            source={Images.iconShareColored}
            width={16}
            height={16}
            layout="fixed"
            alt="shared followers"
          />
          <Text style={[styles.infoText, { marginLeft: 4 }]}>{"-"}</Text>
        </View> */}
      </View>
    </View>
  );
};

const ArtistCardInfoView = (props) => {
  const { isMobileVersion } = props;
  const totalEventString = formatNumber(props.item.totalEvent);
  const totalFollowerString = props.item.totalFollower || "-";
  const totalShareString = formatNumber(props.item.totalConnection) || "-";
  const totalLineup = props.item.totalLineup || "-";
  const renderViews = () => {
    return [
      <View key={1} style={styles.rowContainer}>
        <CImage
          key={1}
          source={Images.iconTicket}
          width={16}
          height={16}
          layout="fixed"
          alt="ticket entry"
        />
        <Text
          key={2}
          style={[styles.infoText, { marginLeft: 4, marginRight: 10 }]}>
          {totalEventString}
        </Text>
        {/* <CImage
          source={Images.iconSearchOther}
          width={20}
          height={20}
          layout="fixed"
          alt="followers"
        />
        <Text key={4} style={[styles.infoText, { marginLeft: 4 }]}>
          {totalFollowerString}
        </Text> */}
      </View>,
    ];
  };
  return (
    <View style={[styles.container, props.style]}>
      <Text
        style={[
          styles.title,
          isMobileVersion
            ? {
                fontSize: 12,
                lineHeight: 18,
              }
            : {},
        ]}
        numberOfLines={1}>
        {props.item.displayName}
      </Text>
      <View style={[styles.rowContainer, { marginTop: 10 }]}>
        <Text
          style={[
            styles.description,
            isMobileVersion
              ? {
                  fontSize: 10,
                  lineHeight: 18,
                }
              : {},
          ]}>
          {getSpecialist(props.item.specialist?.split(","))}
        </Text>
        <View style={styles.dot} />
        <Text
          style={[
            styles.description,
            isMobileVersion
              ? {
                  fontSize: 10,
                  lineHeight: 18,
                }
              : {},
          ]}>
          {props.item.country || props.item.location}
        </Text>
      </View>
      <View style={[styles.rowContainer, { marginTop: 10 }]}>
        <DotSplitedViews views={renderViews()} />
        {/* <View style={[styles.rowContainer, { marginLeft: 10 }]}>
          <CImage
            source={Images.iconShareColored}
            width={16}
            height={16}
            layout="fixed"
            alt="shared followers"
          />
          <Text style={[styles.infoText, { marginLeft: 4 }]}>{"-"}</Text>
        </View> */}
      </View>
    </View>
  );
};

const VenueCardInfoView = (props) => {
  const { isMobileVersion, isHome } = props;
  const { isOfficial, isPro } = props.item;
  const [showPopupOfficial, setShowPopupOfficial] = useState(false);
  const totalEventString = formatNumber(props.item.totalEvent);
  const totalFollowerString = props.item.totalFollower || "-";
  const totalShareString = formatNumber(props.item.totalConnection) || "-";
  const totalLineup = props.item.totalLineup || "-";
  const renderViews = () => {
    return [
      <View key={1} style={styles.rowContainer}>
        {/* <CImage
          key={1}
          source={Images.iconTicket}
          width={16}
          height={16}
          layout="fixed"
          alt="ticket entry"
        />
        <Text
          key={2}
          style={[styles.infoText, { marginLeft: 4, marginRight: 10 }]}>
          {totalEventString}
        </Text> */}
        <CImage
          source={Images.iconSearchOther}
          width={20}
          height={20}
          layout="fixed"
          alt="followers"
        />
        <Text key={4} style={[styles.infoText, { marginLeft: 4 }]}>
          {totalFollowerString}
        </Text>
      </View>,
    ];
  };
  return (
    <View style={[styles.container, props.style]}>
      <View
        style={[styles.rowContainer]}
        onMouseOver={() => setShowPopupOfficial(true)}
        onMouseLeave={() => setShowPopupOfficial(false)}>
        <Text
          style={[
            styles.title,
            isMobileVersion
              ? {
                  fontSize: 12,
                  lineHeight: 18,
                }
              : {},
          ]}
          numberOfLines={1}>
          {props.item.displayName}
        </Text>
        {(isOfficial === 1 || isPro === 1) && (
          <View
            style={{
              marginLeft: 6,
              marginTop: 0,
              position: "relative",
            }}>
            <CImage
              source={Images.iconVerifiedBadge}
              alt="badge official"
              width={14}
              height={14}
              layout="fixed"
            />
          </View>
        )}

        {showPopupOfficial && (isOfficial === 1 || isPro === 1) && !isHome && (
          <View
            style={{
              position: "absolute",
              width: isMobileVersion ? 260 : 336,
              height: isMobileVersion ? 140 + 24 : 182 + 24,
              zIndex: 10,
              left: -10,
              top: 0,
              paddingTop: 24,
            }}>
            <View
              style={{
                backgroundColor: Colors.grayBackground,
                width: isMobileVersion ? 260 : 336,
                height: isMobileVersion ? 140 : 182,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                paddingHorizontal: 22,
                borderRadius: 18,
                width: "100%",
                height: "100%",
                boxShadow: "2px 2px 10px rgba(0,0,0,0.5)",
              }}>
              <CImage
                source={Images.iconVerifiedBadgeMedium}
                alt="verified account"
                width={isMobileVersion ? 24 : 35}
                height={isMobileVersion ? 24 : 35}
                layout="fixed"
              />
              <Text
                style={{
                  fontFamily: Fonts.primaryBold,
                  fontSize: isMobileVersion ? 16 : 20,
                  lineHeight: isMobileVersion ? 20 : 24,
                  color: Colors.white,
                  marginVertical: isMobileVersion ? 9 : 14,
                  textAlign: "center",
                }}>
                Official Account
              </Text>
              <Text
                style={{
                  fontFamily: Fonts.primaryRegular,
                  fontSize: isMobileVersion ? 12 : 16,
                  lineHeight: isMobileVersion ? 16 : 18,
                  color: Colors.white80,
                  textAlign: "center",
                }}>
                This is an official account verified by Soundclub
              </Text>
            </View>
          </View>
        )}
      </View>
      <View style={[styles.rowContainer, { marginTop: 10 }]}>
        <Text
          style={[
            styles.description,
            isMobileVersion
              ? {
                  fontSize: 10,
                  lineHeight: 18,
                }
              : {},
          ]}>
          {props.item.tag_venue_1 || "-"}
        </Text>
        <View style={styles.dot} />
        <Text
          style={[
            styles.description,
            isMobileVersion
              ? {
                  fontSize: 10,
                  lineHeight: 18,
                }
              : {},
          ]}>
          {props.item.city || "-"}
        </Text>
      </View>
      {/* <View style={[styles.rowContainer, { marginTop: 10 }]}>
        <DotSplitedViews views={renderViews()} />
      </View> */}
    </View>
  );
};

const CardInfoView = (props) => {
  const { isMobileVersion } = props;
  if (!props.item) return null;
  if (props.category == Constants.categoryType.CATEGORY_EVENT) {
    return <EventCardInfoView {...props} />;
  } else if (props.category == Constants.categoryType.CATEGORY_FESTIVAL) {
    return <FestivalCardInfoView {...props} />;
  } else if (props.category == Constants.categoryType.CATEGORY_ARTIST) {
    return <ArtistCardInfoView {...props} />;
  } else if (props.category == Constants.categoryType.CATEGORY_VENUE) {
    return <VenueCardInfoView {...props} />;
  }

  return (
    <View style={[styles.container, props.style]}>
      <Text
        style={[
          styles.title,
          isMobileVersion
            ? {
                fontSize: 12,
                lineHeight: 18,
              }
            : {},
        ]}>
        {props.item.title}
      </Text>
      <Text
        style={[
          styles.description,
          isMobileVersion
            ? {
                fontSize: 10,
                lineHeight: 18,
              }
            : {},
        ]}>
        {props.item.shortDescription}
      </Text>
      <View style={styles.rowContainer}>
        <Text style={styles.location}>{props.item.city}</Text>
        {props.item.gemCount && <View style={styles.dot} />}
        {props.item.gemCount && (
          <View style={styles.row}>
            <CImage
              key={1}
              width={16}
              height={16}
              layout="fixed"
              source={Images.iconTicket}
              style={styles.icon}
              resizeMode="contain"
              alt="ticket entry"
            />
            <Text style={styles.location}>{props.item.gemCount}</Text>
          </View>
        )}
      </View>
    </View>
  );
};
const styles = StyleSheet.create({
  rowContainer: {
    flexDirection: "row",
    alignItems: "center",
  },
  container: {
    flexDirection: "column",
  },
  title: {
    fontSize: 16,
    lineHeight: 18,
    fontFamily: Fonts.primaryBold,
    color: Colors.white,
    textAlign: "left",
  },
  description: {
    fontSize: 14,
    lineHeight: 18,
    fontFamily: Fonts.primaryRegular,
    color: Colors.darkGray,
  },
  dot: {
    height: 2,
    width: 2,
    borderRadius: 1,
    marginHorizontal: 6,
    backgroundColor: Colors.lightGray,
  },
  location: {
    fontSize: 16,
    lineHeight: 18,
    fontFamily: Fonts.primaryMedium,
    color: Colors.lightGray,
  },
  infoText: {
    fontSize: 14,
    lineHeight: 14,
    fontFamily: Fonts.primaryRegular,
    color: Colors.lightGray,
  },
  row: {
    flexDirection: "row",
    alignItems: "center",
  },
  icon: {
    width: 16,
    height: 16,
    marginRight: 4,
  },
});
export default CardInfoView;
