import React from "react";
import { Images } from "../../../../config";
import { StyleSheet, TouchableOpacity } from "react-native-web";
import Image from "next/image";
const ActiveButton = (props) => {
  return (
    <TouchableOpacity
      style={[styles.container, props.style]}
      onPress={props.onPress}
    >
      {props.isActive && (
        <div
          className="bg-gradient-to-l from-gradient3 via-gradient2 to-gradient1"
          style={styles.mask}
        />
      )}
      <Image
        src={Images.iconPlus}
        style={props.plusIcon}
        width="14"
        height="14"
      />
    </TouchableOpacity>
  );
};

export default ActiveButton;

const styles = StyleSheet.create({
  container: {
    width: 30,
    height: 30,
    backgroundColor: "#53535399",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 15,
    overflow: "hidden",
  },
  mask: {
    right: 0,
    bottom: 0,
    top: 0,
    left: 0,
    position: "absolute",
  },
  plusIcon: {
    resizeMode: "contain",
  },
});
